#duel {
  #duel-topbar {
    display: flex;
    margin-bottom: 0.7rem;

    & > div {
      &:first-of-type {
        flex-grow: 1;
        display: flex;
        align-items: center;
        margin-right: 0.8rem;

        & > div {
          flex-grow: 1;
          width: 0.3rem;
          height: 0.5rem;
          background-color: $color-gray-wait;
          margin-right: 0.3rem;

          body.dark & {
            background-color: $color-gray-wait-dark;
          }

          &.bad {
            background-color: $color-red-error !important;
          }

          &.good {
            background-color: $color-green-success !important;
          }
        }
      }

      &:last-of-type {
        flex-shrink: 0;
        font-size: 0.9rem;
        text-align: center;
      }
    }
  }
}
