main#login {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2,
  input {
    margin: 10px auto;
  }

  h1 {
    margin: 2rem auto 1rem;
    font-size: 1.5em;
  }

  h2 {
    font-style: italic;
    span {
      white-space: nowrap;
    }
  }

  input {
    display: block;
    margin: 10px;
    width: 200px;
  }
}
