html,
body,
#root {
  font-family: sans-serif;
  width: 100%;
  position: relative;
  height: 100%;
}

body.dark {
  background-color: $color-bg-dark;

  #root {
    background-color: $color-bg-dark;
    color: $text-color-black;
  }
}

/**
 * Main contain the content of each page
 */
main {
  padding: 0;
  margin: 0;
  min-height: calc(100% - 50px);
  background-color: $color-bg;
  box-sizing: border-box;

  body.dark & {
    background-color: $color-bg-dark;
    color: white;
  }
}

@media only screen and (min-device-width: $screen-desktop) {
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d7a691;

    &.dark {
      background-color: #9e7462;
    }
  }

  #root {
    width: 600px;
    height: calc(100vh - 20px - 2vh);
    max-height: 1100px;
    padding: 0;
    border: solid 10px black;
    border-radius: 20px;
    overflow-y: auto;
    background-color: $color-bg;
  }
}
