main#profile {
  padding-top: 1rem;

  #options {
    padding: 1rem 0;
    text-align: center;
  }

  .profile-avatar {
    position: relative;
    &-image > .avatar {
      margin: auto;
    }
  }

  .btn {
    margin: 10px auto;
  }

  select {
    border: none;
    padding: 0.4rem 0.5rem;
    border-radius: 4px;
    margin-top: 0.3rem;
    background-color: $color-gray;

    body.dark & {
      background-color: $color-gray-dark;
      color: white;
    }
  }
}
