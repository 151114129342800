main#duel-overview {
  padding: 20px 1.2em;

  header {
    padding: 0 1rem 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: $screen-desktop) {
      justify-content: space-evenly;
    }

    & > span {
      font-size: 1.8rem;

      @media screen and (max-width: $screen-smartphone-small) {
        font-size: 1.4rem;
      }
    }

    .badge {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > span {
        margin-top: 0.1em;
      }
    }
  }

  & > .btn {
    width: auto;
    margin: 0rem auto 3rem;
    text-align: center;
  }

  p#TTL {
    text-align: center;
    padding: 1.1rem 0 0.6rem 0;
    font-size: 0.9rem;
    font-style: italic;
  }

  section {
    $title-height: 35px;
    $color-bg-color: $color-gray;

    margin: $title-height auto 1rem auto;
    padding: 0.8em 0;
    background-color: $color-bg-color;
    border-radius: $button-border-radius;
    position: relative;

    display: flex;
    justify-content: space-evenly;

    body.dark & {
      background-color: $color-gray-dark;
    }

    h3 {
      position: absolute;
      top: -$title-height + 10px;
      left: 0;
      font-weight: bold;
      background-color: $color-bg-color;
      padding: 8px 12px;
      border-top-left-radius: $button-border-radius;
      border-top-right-radius: $button-border-radius;
      max-width: calc(100% - 2 * 12px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      body.dark & {
        background-color: $color-gray-dark;
      }

      span {
        font-weight: lighter;
      }
    }

    .result {
      margin: auto 0;
      width: 45%;

      & > span {
        display: block;
        margin-bottom: 0.3rem;
        text-align: center;
        font-size: 0.9rem;
      }

      .bricks {
        display: flex;
        justify-content: space-between;

        .brick {
          display: inline-block;
          flex-grow: 1;
          margin: 0 2px;
          background-color: $color-gray-wait;
          height: 0.8rem;

          body.dark & {
            background-color: $color-gray-wait-dark;
          }

          &.true {
            background-color: $color-green-success !important;
          }

          &.false {
            background-color: $color-red-error !important;
          }
        }
      }
    }
  }
}

body.dark #duel-overview {
  .duel-results {
    background-color: $color-gray-dark;
  }
}
