/* ---------- For all buttons ---------- */

button {
  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.4;
  }
}

/* ---------- Default button ---------- */

.btn {
  display: block;
  width: max-content;
  border: none;
  background: $button-bg;
  color: $button-fg;
  border-radius: $button-border-radius;
  padding: 0.9rem 1.6rem;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(134, 134, 134, 0.4);
  flex-shrink: 0;
  text-decoration: none;
  text-align: center;

  body.dark & {
    background-color: $button-bg-dark;
    box-shadow: none;
  }

  &[disabled] {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }
}

/* ---------- Full width button ---------- */

.btn.btn-fw {
  width: 80%;
  margin: auto;
}

/* ---------- Button circle ---------- */

button.btn.btn-circle {
  border-radius: 50%;
  margin: auto;
  padding: 1rem;

  svg {
    margin: 0;
    width: 25px;
    height: auto;
  }
}

/* ---------- Update app button ---------- */

#update-app {
  position: fixed;
  bottom: -5rem;
  left: 50%;
  transform: translateX(-50%) scale(1);
  background-color: $mascot-blue-light;
  color: white;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 1px $mascot-blue-dark;
  font-size: 0.8rem;
  padding: 0.8rem 1.3rem;
  text-transform: initial;
  z-index: 1000;

  transition: transform 0.2s ease-in-out;
  animation: appear-update 0.5s ease-in-out forwards;

  svg {
    margin-right: 0.4rem;
  }

  &:active {
    transform: translateX(-50%) scale(1.1);
  }
}

@keyframes appear-update {
  0% {
    bottom: -5rem;
  }
  100% {
    bottom: 3rem;
  }
}

.update-animation {
  svg {
    animation: loading-update 1.2s linear infinite;
  }
}

@keyframes loading-update {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
