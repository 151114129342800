main#create-duel {
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 0.7rem;
  }

  input {
    padding: 0.7rem 0.6rem;
    border: 1px solid $button-bg;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;

    body.dark & {
      background-color: $color-gray-dark;
      color: white;
    }
  }

  section {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;

    &:nth-of-type(2) {
      overflow: auto;
      padding-top: 0.5rem;
      padding-bottom: 1rem;
    }

    &:nth-of-type(3) {
      position: sticky;
      bottom: 0;
      left: 0;
      margin-top: auto;
      background-color: white;
      text-align: center;
      padding: 0.9rem 1rem;
      border-top: 1px solid $button-bg;
      z-index: 100;

      body.dark & {
        background-color: $color-gray-dark;
      }

      p {
        padding: 0.9rem 1.6rem;
      }
    }

    #filters {
      text-align: center;
      h2 {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
        padding-top: 1rem;
      }

      select {
        min-width: 15ch;
        max-width: 30ch;
        border: 1px solid black;
        border-radius: 0.25em;
        padding: 0.25em 0.5em;
        font-size: 0.9rem;
        cursor: pointer;
        line-height: 1.1;
        background-color: #fff;
      }
      input {
        width: inherit;
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 7px;

    &.selected {
      background-color: $button-bg;

      body.dark & {
        background-color: $button-bg-dark;
      }

      p {
        color: white;
      }
    }

    p {
      font-size: 1.1rem;
      margin-left: 0.8rem;
    }
  }
}
