main#menu {
  display: flex;
  flex-direction: column-reverse;
  background-image: url("../../images/bg_menu.jpg");
  background-size: cover;
  background-position: top;

  section {
    padding-bottom: 2.5rem;

    a {
      margin-bottom: 1rem;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

/* ---------- Install app button ---------- */

button#installButton {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 70%;
  padding: 0;
  border: none;
  background-color: $mascot-blue-light;
  margin: 1rem auto;
  border-radius: 6px;
  overflow: hidden;
  color: white;
  font-size: 1rem;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.06);
  }

  span {
    padding: 1.1rem 1.3rem;

    &:first-of-type {
      background-color: $mascot-blue-dark;
    }
  }
}
