.avatar {
  overflow: hidden;
  position: relative;

  pointer-events: none;
  @include not-selectable;

  .avatar-inside {
    $border-width: 2px;

    width: calc(100% - 2px - 2 * #{$border-width});
    height: calc(100% - 2px - 2 * #{$border-width});
    top: 1px;
    left: 1px;
    position: relative;
    border: $border-width solid black;
    border-radius: 50%;

    svg,
    img {
      --offset: 0;
      width: 100%;
      position: absolute;
      margin-top: calc(-100% * var(--offset));
    }

    @for $i
      from 1
      through max(
        $number-choices-eyes,
        $number-choices-hands,
        $number-choices-hats,
        $number-choices-mouthes
      )
    {
      img[data-choice="#{$i}"] {
        --offset: #{$i};
      }
    }

    .avatar-eyes {
      z-index: 2;
    }
    .avatar-hands {
      z-index: 4;
    }
    .avatar-hat {
      z-index: 3;
    }
    .avatar-mouth {
      z-index: 2;
    }
    .avatar-body-top {
      z-index: 1;
    }
    .avatar-body-bottom {
      z-index: 0;
    }
  }
}
