#change-avatar {
  position: relative;
  display: grid;

  &.error {
    // to properly display the floating error component
    display: block;
  }

  padding: 10px;
  vertical-align: middle;

  grid-template-columns: 1fr 0.3fr 1fr 0.3fr;
  grid-template-areas:
    "a b c d"
    "a b c d"
    "a b c d"
    "button-left button-left button-right button-right"
    "span-middle span-middle span-middle span-middle";

  gap: 15px 20px;

  * {
    @include not-selectable;
  }

  span,
  div.choice {
    display: flex;
    align-items: center;
  }

  div.choice {
    margin: auto;
    touch-action: manipulation;

    .disabled {
      pointer-events: none;
      color: lightgray;

      body.dark & {
        color: #5d5d5d;
      }
    }

    svg {
      cursor: pointer;
    }
  }

  label {
    display: flex;
    align-items: center;

    svg {
      margin: auto;
    }
  }

  select,
  input {
    height: 100%;
  }

  button {
    width: 100%;
    height: 100%;

    &:first-of-type {
      grid-area: button-left;
    }

    &:last-of-type {
      grid-area: button-right;
    }
  }

  span.error {
    grid-area: span-middle;
    text-align: center;
  }
}
