input {
  font-size: 1rem;
}

.error {
  color: red;
}

b {
  font-weight: bold;
}

i {
  font-style: italic;
}

p {
  color: $text-color;

  body.dark & {
    color: $text-color-black;
  }
}

summary {
  cursor: pointer;
}

svg {
  vertical-align: bottom;
}

button {
  touch-action: manipulation;
}
