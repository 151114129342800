#homepage {
  header {
    position: relative;
    display: flex;
    padding: 1rem 1.1rem 2.2rem 1.1rem;
    align-items: center;
    overflow: hidden;

    #header-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../images/bg_menu.jpg") no-repeat top;
      background-size: cover;
      filter: blur(1px);

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);

        body.dark & {
          background: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .avatar {
      margin-right: 0.8rem;
    }

    & > div:last-of-type {
      z-index: 2;

      h1 {
        font-size: 1.7rem;
        margin-bottom: 0.4rem;
        color: white;
      }

      p {
        color: white;
        line-height: 1.2;
      }
    }
  }

  #links {
    display: flex;
    justify-content: center;
    transform: translateY(-50%);

    .btn:first-child {
      margin-right: 1rem;
    }
  }

  section {
    padding: 0 1.2rem 1.8rem 1.2rem;

    h2 {
      font-size: 1.3rem;
      font-weight: bold;
      color: #252525;

      display: flex;
      align-items: center;
      height: 4.2rem;
      overflow: hidden;

      body.dark & {
        color: #ffffff;
      }

      .animation,
      img {
        display: inline-block;
        vertical-align: middle;
        height: 3rem;
        padding-right: 1rem;
      }
    }

    article {
      display: flex;
      align-items: center;
      margin-bottom: 0.9rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      & > *:first-of-type {
        margin-right: 0.8rem;
      }

      & > a {
        text-decoration: none;
        color: transparent;
      }

      .challenges-text {
        background: $color-gray;
        padding: 0.8rem 1rem;
        border-radius: 7px;
        flex-grow: 1;
        position: relative;

        body.dark & {
          background: $color-gray-dark;

          p {
            color: #dadada;
          }
        }

        h3 {
          font-size: 1.2rem;
          margin-bottom: 0.4rem;
          color: black;

          body.dark & {
            color: white;
          }
        }

        p.time {
          position: absolute;
          font-size: 0.8rem;
          top: 0.7rem;
          right: 0.8rem;

          svg {
            margin-right: 0.2rem;
          }
        }
      }
    }

    section > article:last-of-type {
      margin-bottom: 0;
    }

    &:first-of-type {
      .challenges-text {
        display: flex;
        justify-content: space-between;

        a {
          margin-right: 0.5rem;
        }
      }
    }

    &:nth-of-type(2) {
      article {
        justify-content: flex-end;

        & > a.challenges-text {
          &:first-of-type {
            text-align: right;
          }

          p.time {
            left: 0.8rem;
            right: auto;
          }
        }
      }
    }
  }

  .avatar {
    flex-shrink: 0;
  }
}

@media screen and (max-width: $screen-smartphone-small) {
  span.time-important {
    font-size: 0.7rem;
  }
}
