#quiz {
  @media screen and (min-width: $screen-desktop) {
    padding: 1.2rem;
  }

  /* ---------- Intro ---------- */

  &.quiz-intro {
    padding: 1rem 0;

    img {
      width: 90%;
      float: right;

      @media screen and (min-width: $screen-desktop) and (max-height: 860px) {
        width: 70%;
      }

      @media screen and (max-width: $screen-desktop) {
        width: 70%;
      }
    }

    div {
      clear: both;
      margin-bottom: 2rem;
      text-align: center;

      h1 {
        font-size: 2rem;
        margin-bottom: 0.3rem;
      }

      #about {
        font-size: 1rem;
      }
    }

    button {
      margin: auto;
    }

    #filters {
      padding-top: 0.8rem;
      text-align: center;
      h2 {
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
        padding-top: 0.6rem;
      }

      select {
        min-width: 15ch;
        max-width: 30ch;
        border: 1px solid black;
        border-radius: 0.25em;
        padding: 0.25em 0.5em;
        font-size: 1rem;
        cursor: pointer;
        line-height: 1.1;
        background-color: #fff;
      }
    }
  }

  /* ---------- Summary ---------- */

  &.quiz-summary {
    h1 {
      font-size: 1.6rem;
      text-align: center;
      margin-bottom: 0.3rem;
    }

    h1 ~ p {
      font-size: 1.3rem;
      text-align: center;
      margin-bottom: 1.2rem;
    }

    summary,
    h2 {
      font-size: 1.2rem;
      margin-bottom: 0.4rem;
    }

    details {
      margin-bottom: 1.2rem;

      summary:focus {
        outline: none;
      }
    }

    ul {
      li {
        margin-bottom: 0.8rem;

        p:first-of-type {
          font-style: italic;
          margin-bottom: 0.1rem;
        }
      }
    }

    details ul {
      p:last-of-type {
        color: $color-green-success;
        font-weight: bold;
      }
    }

    h2 ~ ul {
      padding-bottom: 0.5rem;

      p:last-of-type {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        span:first-of-type {
          color: $color-red-error;
          text-align: right;
        }

        span:nth-of-type(2) {
          text-align: center;
        }

        span:last-of-type {
          color: $color-green-success;
          font-weight: bold;
        }
      }
    }
  }

  /* ---------- Quiz topbar ---------- */

  #quiz-topbar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 0.2rem;
    margin-bottom: 1rem;

    span {
      &#score-good {
        color: $color-green-success;
        margin-right: 0.6rem;
      }
      &#score-bad {
        color: $color-red-error;
      }
    }

    div:last-of-type {
      text-align: right;

      button {
        display: inline;
        border: none;
        background: transparent;
        padding: 0;
        color: black;
        box-shadow: none;

        body.dark & {
          color: white;
        }

        svg {
          color: #5a5a5a;
        }
      }
    }

    @media screen and (max-width: $screen-smartphone-small) {
      margin-bottom: 0.9rem;
    }

    @media screen and (min-width: $screen-desktop) {
      margin-bottom: 1.2rem;
    }
  }
}
